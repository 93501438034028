<template>
  <div class="contant">
    <div class="topHedaer">
      <div class="logo">
        <img src="https://resource.jiupiao.vip/feedback/20230703/168835440405585.png" alt="">
      </div>
      <div class="headerList">
        <div v-for="item in page" :key="item">
          <div v-if="item.title === '联系我们'" class="title" @click="scrollToBottom">联系我们</div>
          <router-link v-if="item.title !== '联系我们'" :to="item.link">
            <div class="title">{{ item.title }}</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.contant {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100px;
  background-color: transparent;
}

.topHedaer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  padding: 4.01vw 5vw 0 5.36vw;
}

.logo > img {
  width: 12.34vw;
  height: 3.13vw;
}

.headerList {
  color: #fff;
  display: flex;
}

.title {
  padding: 4.74vw 0 0;
  font-size: 1.25vw;
  width: 14vw;
  text-align: center;
}

/* 清除 router-link 样式 */
router-link {
  text-decoration: none;
  color: inherit;
}

router-link:hover,
router-link:active {
  text-decoration: none;
  color: inherit;
}

</style>

<script setup>
const page = [
  {
    title: '首页',
    link: '/',
  },
  {
    title: '产品展示',
    link: '/product',
  },
  {
    title: '招商加盟',
    link: '/joinus',
  },
  {
    title: '企业文化',
    link: '/corporate',
  },
  {
    title: '联系我们',
  },
]
import {nextTick, onMounted, ref, watchEffect} from 'vue';

const scrollContainer = ref(null);

// 监听页面内容高度变化并滚动至底部
watchEffect(() => {
  const container = scrollContainer.value;
  if (container) {
    const bottomElement = container.scrollHeight - container.clientHeight;
    container.scrollTo({
      top: bottomElement,
      behavior: 'smooth'
    });
  }
});

// 滚动容器
onMounted(() => {
  nextTick(() => {
    scrollContainer.value = document.documentElement;
  });
});

// 滚动至底部方法
const scrollToBottom = () => {
  const container = scrollContainer.value;
  if (container) {
    const bottomElement = container.scrollHeight - container.clientHeight;
    container.scrollTo({
      top: bottomElement,
      behavior: 'smooth'
    });
  }
};
</script>
