<template>
  <div class="contant">
    <div class="footerBody">
      <div class="leftFooter">
        <img src="https://resource.jiupiao.vip/feedback/20230703/1688370572318142.png" alt="">
      </div>
      <div class="rightFooter">
        <div class="contentUs">
          <div class="title">联系信息</div>
          <div class="content">西安青缇科技有限公司</div>
          <div class="content">客服电话：029-85792676</div>
        </div>
        <div class="beian">
          <a href="https://beian.miit.gov.cn/">陕ICP备2023007793号-2</a>
        </div>
        <div class="contentUs">
          <div class="title">扫一扫</div>
          <div class="codeBox">
            <div class="code" v-for="item in footerCode" :key="item">
              <img :src="item.img" alt="">
              <div>{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
const footerCode = [
  {
    img: 'https://resource.jiupiao.vip/feedback/20230703/1688371054304118.png',
    title: '企业微信',
  },
  {
    img: 'https://resource.jiupiao.vip/feedback/20230703/168837108130458.png',
    title: '微信公众号',
  },
  {
    img: 'https://resource.jiupiao.vip/feedback/20230703/168837108130458.png',
    title: '酒票APP',
  },
]
</script>

<style scoped>
.contant {
  width: 100%;
  background-color: #24262D;
  padding: 9vw 0;
}

.footerBody {
  width: 94vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.leftFooter img {
  width: 16.64vw;
  height: 15.68vw;
  margin-left: 3vw;
}

.rightFooter {
  display: flex;
  width: 60vw;
  justify-content: space-between;
  border-bottom: .1vw solid #666666;
}

.rightFooter .title {
  color: #BD4E49;
  font-size: 1.67vw;
  font-weight: bold;
}

.rightFooter .content {
  color: #999999;
  font-size: 1.25vw;
  margin-top: 1.45vw;
}

.rightFooter .content::before {
  content: "";
  display: inline-block;
  width: .52vw;
  height: .52vw;
  background-color: #BD4E49;
  border-radius: 50%;
  margin-right: 5px;
}

.codeBox {
  display: flex;
  justify-content: space-between;
  width: 30vw;
}

.contentUs .code img {
  width: 6.46vw;
  height: 6.46vw;
  margin-top: 1vw;
}

.contentUs .code div {
  color: #999999;
  font-size: 1.04vw;
  text-align: center;
  margin-top: 1vw;
}
.beian{
  position: absolute;
  margin-top: 300px;
  color: #999999;
}
.beian:hover{
  border-bottom: 1px solid #999999;
}
</style>
