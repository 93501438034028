import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
    {
        path: '/',
        redirect: '/home',
    },
    {
        path: '/home',
        name: 'home',
        component: () => import(/* webpackChunkName: "home" */ '../views/homePage.vue'),
    },
    {
        path: '/product',
        name: 'product',
        component: () => import(/* webpackChunkName: "home" */ '../views/productDisplay.vue'),
    },
    {
        path: '/joinus',
        name: 'joinus',
        component: () => import(/* webpackChunkName: "home" */ '../views/join-us.vue'),
    },
    {
        path: '/corporate',
        name: 'corporate',
        component: () => import(/* webpackChunkName: "home" */ '../views/corporate.vue'),
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

export default router
