import axios from 'axios';
import {clearToken, getToken} from '@/utils/auth';

axios.interceptors.request.use(
    (config) => {
        if (!config.headers) {
            config.headers = {
                cid: '1000402',
                ver: '1.0.0',
            };
        } else {
            config.headers.cid = '1000402';
            config.headers.ver = '1.0.0';
        }
        if (!config.responseType) {
            config.responseType = 'json';
        }

        config.headers.ver = '1.0.0';

        const token = getToken();
        if (token) {
            config.headers.sid = token;
        }
        config.baseURL = 'https://www.lxiaoc.com/api/';
        return config;
    },

    (error) => {
        // do something
        return Promise.reject(error);
    }
);
// add response interceptors
axios.interceptors.response.use(
    (response) => {
        const res = response.data;
        if (response.config.responseType !== 'json') {
            return Promise.resolve(response);
        }
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 0) {
            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if ([50103].includes(res.code)) {
                clearToken();
                window.location.href = '/';
            }
            return Promise.resolve(res);
        }
        return Promise.resolve(res);
    },
    (error) => {
        return Promise.reject(error.response);
    }
);
